.product-show-order-items-button {
  .product-order-items-button {
    display: flex;
    width: 38px;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0 none;
    background-color: transparent;

    &--has-items,
    &--has-no-items {
      width: calc(var(--icon-size-large) + 2px);
      height: calc(var(--icon-size-large) + 2px);
    }

    &--has-items {
      color: var(--primary-color);
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: var(--primary-color);
        border-radius: 50%;

        .cls-1 {
          color: var(--white);
        }
      }
    }

    &--has-no-items {
      color: var(--table-borders);
      // stylelint-disable-next-line
      cursor: not-allowed;
    }
  }
}

.product-show-order-items-tooltip {
  color: var(--primary-color);

  h5 {
    margin-bottom: 0;
    font-weight: unset;
  }

  .order-item__items {
    display: flex;
    flex-direction: column;
    margin-top: var(--spacing-xxs);

    &__item > span {
      margin-right: var(--spacing-sm);
    }
  }
}
