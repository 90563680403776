@import "~antd/dist/antd.less";
@import "less/variables/index";
@import "less/util/mixins";
@import "less/fonts";
@import "less/layout";
@import "less/links";
@import "less/icon";
@import "less/buttons";
@import "less/header";
@import "less/foldoutMenu";
@import "less/shopCategoryNavigation";
@import "less/iconNavigation";
@import "less/footer";
@import "less/register";
@import "less/login";
@import "less/categories";
@import "less/products";
@import "less/podcast";
@import "less/seminars";
@import "less/articlePage";
@import "less/article";
@import "less/newsBanner";
@import "less/downloadModal";
@import "less/contentCard";
@import "less/paginationWrapper";
@import "less/filters";
@import "less/picture";
@import "less/product";
@import "less/productDetail";
@import "less/productSlider";
@import "less/divider";
@import "less/util/util";
@import "less/backButton";
@import "less/detailText";
@import "less/price";
@import "less/modal";
@import "less/breadcrumbs";
@import "less/note";
@import "less/teaser";
@import "less/infoCenter";
@import "less/productBundle";
@import "less/news/_news";
@import "less/searchInput";
@import "less/suggest";
@import "less/quickOrder";
@import "less/forms";
@import "less/userProfile";
@import "less/scanner/scanner";
@import "less/message";
@import "less/collapseAlert";
@import "less/contactPerson";
@import "less/selectSort";
@import "less/datePicker/datePicker";
@import "less/datePicker/deliveryDate";
@import "less/datePicker/dateSwitcher";
@import "less/categoriesDropdown";
@import "less/productItemTable/productItemTable";
@import "less/popupMenu";
@import "less/priceTagList";
@import "less/list";
@import "less/collaps";
@import "less/order/orderNotes";
@import "less/editableCaption";
@import "less/favouriteListsSelector";
@import "less/addFavouriteListModal";
@import "less/inputWithButton";
@import "less/deleteFavouriteListButton";
@import "less/cart";
@import "less/cart/cartDeleteModal";
@import "less/cart/cartItem";
@import "less/cart/cartItemsList";
@import "less/cart/deletedOrChangedCartItemEntry";
@import "less/cart/minimumOrderLimits";
@import "less/cart/productButtonMenu";
@import "less/cart/totalSum";
@import "components/atoms/index";
@import "components/molecules/index";
@import "components/organisms/index";
@import "components/pages/index";
@import "less/antModal";
@import "less/cart/cartMoveItemDeleteItemCombination";
@import "less/product/productOrderNotes";
@import "less/navNode";
@import "less/topNavigation";
@import "less/favouriteListTopBar.less";

html {
  &:not(.no-scroll-behavior) {
    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: smooth;
    }
  }

  &:has(.weekplanner-new) {
    scrollbar-gutter: stable;
  }
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  user-select: none;
}

.spin {
  position: absolute;
  top: 48%;
  left: 48%;
}

.hidden-xs-down {
  @media only screen and (max-width: @screen-xs-max) {
    display: none !important;
  }
}

.hidden-sm-down {
  @media only screen and (max-width: @screen-sm-max) {
    display: none !important;
  }
}

.hidden-md-down {
  @media only screen and (max-width: @screen-md-max) {
    display: none !important;
  }
}

.hidden-md-down-min {
  @media only screen and (max-width: @screen-md-min) {
    display: none !important;
  }
}

.hidden-lg-down {
  @media only screen and (max-width: @screen-lg-max) {
    display: none !important;
  }
}

.hidden-xl-down {
  @media only screen and (max-width: @screen-lg-max) {
    display: none !important;
  }
}

.hidden-sm-up {
  @media only screen and (min-width: @screen-sm-min) {
    display: none !important;
  }
}

.hidden-md-up {
  @media only screen and (min-width: @screen-md-min) {
    display: none !important;
  }
}

.hidden-md-up-max {
  @media only screen and (min-width: @screen-md-max) {
    display: none !important;
  }
}

.hidden-lg-up {
  @media only screen and (min-width: @screen-lg-min) {
    display: none;
  }
}

.hidden-xl-up {
  @media only screen and (min-width: @screen-xl-min) {
    display: none;
  }
}

.show-print {
  display: none;
}

@media print {
  print-color-adjust: exact !important;

  .ant-col-lg-5 {
    max-width: 100%;
  }

  .ant-tooltip {
    display: none !important;
  }

  .hidden-print {
    display: none !important;
  }

  .show-print {
    display: block;
    print-color-adjust: exact !important;
  }
}

@primary-color: #51035a;@heading-color: #51035a;