.ad-product-listing-top {
  padding: var(--spacing-s);
  margin-bottom: var(--spacing-sm);
  background-color: var(--light-grey);

  .picture-tile {
    max-height: unset;
    border-radius: 0;
  }

  .picture-tile__thumbnail,
  .picture-tile__thumbnail picture,
  .picture-tile__thumbnail img {
    height: 100%;
    max-height: 400px;
    border-radius: 0;
  }

  .picture-tile__thumbnail img {
    object-fit: contain;
    object-position: center;
  }

  .picture-tile__overlay {
    height: 100%;
    max-height: 100%;
    background-color: unset;

    &__link .quick-link--primary--outlined:hover,
    &__link .quick-link--primary--outlined:focus {
      border-color: var(--primary-color);
    }
  }
}
