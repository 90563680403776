.weekplanner-item-product {
  display: flex;
  min-width: @weekplanner-item-product-width;
  flex: 1 0 0;
  flex-direction: row;
  gap: var(--spacing-xs);
  justify-items: center;

  &__product-image {
    overflow: hidden;
    width: 75px;
    min-width: 75px !important;

    img {
      width: 75px;
      height: 75px;
      object-fit: contain;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: var(--spacing-xs);

    > div:first-of-type {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      gap: var(--spacing-xs);

      .productInsignias {
        display: none;
      }
    }

    .productTitle {
      margin-bottom: 0;
    }

    .productAttributes {
      align-self: unset;
    }

    .productAttributeDetailIcon svg {
      cursor: pointer;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: var(--spacing-xs);

    .productInsignias {
      img.insignie {
        max-height: calc(var(--icon-size-medium) + 4px);
        margin-left: var(--spacing-xxs);
      }
    }

    > div:first-of-type {
      flex-grow: 1;
    }

    > div:last-of-type {
      display: flex;
      flex-flow: row;
      align-items: flex-end;
      justify-content: flex-end;
      gap: var(--spacing-s);

      .productInfoIcon {
        margin-left: 0;
      }
    }

    svg.icon {
      max-width: 24px;
      max-height: 24px;
    }
  }

  .product-advert-badge__weekplanner--default {
    position: absolute;
    top: 0;
    height: 80px;
    justify-content: center;
  }

  .product-poster-badge {
    position: absolute;
    bottom: 0;
    left: 22px;
  }

  .productNewBadge,
  .productFollowupBadge {
    position: absolute;
    top: 0;
  }

  &:has(.product-advert-badge__weekplanner--default) {
    .productNewBadge,
    .productFollowupBadge {
      left: 22px;
    }
  }

  @media only screen and (max-width: 1400px) and (orientation: landscape) {
    &__product-image {
      width: 65px;
      min-width: 65px !important;
    }

    &__info {
      font-size: var(--text-s);
      gap: var(--spacing-xxs);

      > div:first-of-type {
        gap: var(--spacing-xxs);

        .productInsignias {
          display: flex;

          img.insignie {
            max-height: calc(var(--icon-size-extra-small) + 2px);
            margin-left: var(--spacing-xxs);
          }
        }
      }

      .productTitle {
        max-width: 170px;
        height: var(--text-s);

        .productTitleLink {
          width: 100%;

          .productTitleInner {
            display: block;
            overflow: hidden;
            width: 100%;
            font-size: var(--text-s);
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      .productTitle.isUnavailable {
        .productTitleLink {
          width: calc(100% - var(--icon-size-medium) - var(--spacing-xs));
        }

        .unavailableIcon {
          margin-right: var(--spacing-xs) !important;
        }

        .unavailableIcon,
        .isUnavailable,
        svg {
          max-width: var(--icon-size-medium);
          max-height: var(--icon-size-medium);
        }
      }

      .productAttributeComposition {
        display: none;
      }
    }

    &__actions {
      justify-content: space-around;

      .productInsignias {
        display: none;
      }

      > div:last-of-type {
        flex-direction: column;
        gap: var(--spacing-xs);
      }

      svg.icon {
        max-width: var(--icon-size-medium);
        max-height: var(--icon-size-medium);
      }
    }
  }
}
