.productTitle {
  && {
    display: flex;
    flex-direction: row;
    align-items: center;
    grid-area: title;

    .productTitleLink {
      color: @primary-color;
    }

    &.isUnavailable {
      color: @text-color;

      .unavailableIcon {
        display: inline-flex;
        width: var(--icon-size-default);
        height: var(--icon-size-default);
        margin-right: var(--spacing-s);

        svg {
          width: var(--icon-size-default);
          height: var(--icon-size-default);
        }
      }
    }

    &.weekplannerProductTitle {
      display: inline;

      .unavailableIcon {
        position: relative;
        top: @spacing-xs;
        display: inline;

        svg {
          width: var(--icon-size-medium);
          height: var(--icon-size-medium);
        }
      }
    }

    &.detailProductTitle {
      align-items: baseline;
    }
  }

  > .preOrderIcon:hover,
  > .preOrderIcon:focus {
    cursor: pointer;
  }
}

.pre-order-note-list {
  padding: var(--spacing-s) var(--spacing-m);
  margin: 0;

  > li {
    margin-bottom: var(--spacing-s);
    font-size: @text-default;
    line-height: @line-height-normal;
  }
}
