.webshop-product-information-widget {
  .content-block__content > div {
    & > div:nth-child(1) > .webshop-product-information {
      padding-top: 0 !important;
    }

    @media screen and (min-width: @screen-lg-max) {
      & > div:nth-child(-n + 2) > .webshop-product-information {
        padding-top: 0 !important;
      }

      & > div:nth-child(odd) {
        padding-right: var(--spacing-sm) !important;
      }

      & > div:nth-child(even) {
        padding-left: var(--spacing-sm) !important;
      }
    }
  }

  .webshop-product-information-widget__more {
    margin-top: var(--spacing-2xl);
    text-align: center;

    .button {
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .content-block__content {
    padding-bottom: 0;
  }
}
